.App {
  text-align: center;
  padding: 0 4rem;
}

@media only screen and (max-width: 481px) {
  .App {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 482px) and (max-width: 961px) {
  .App {
    padding: 0 2rem;
  }
}

.hero-gradient {
  background-image: url("./assets/background/hero-section.jpg");
  background-size: 900px 800px;
  background-repeat: no-repeat;
  background-position: top right;
  margin-top: -2rem;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 2rem 4rem; /* 2rem top and bottom, 4rem left and right */
}

@media only screen and (max-width: 481px) {
  .hero-gradient {
    padding: 2rem 1rem;
    margin: 0 -1rem;
  }
}

.button-shade-tl {
  background-image: url("./assets/background/button-shade-tl.png");
  background-size: "contain";
  background-repeat: no-repeat;
  background-position: top left;
  /* 2rem top and bottom, 4rem left and right */
}
.key-features-gradient {
  background-size: 500px 550px;
  background-image: url("./assets/background/key-features-gradient.png");

  background-repeat: no-repeat;
  background-position: top left;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 0 4rem;
  /* 2rem top and bottom, 4rem left and right */
}

@media only screen and (max-width: 481px) {
  .key-features-gradient {
    padding: 0 1rem;
    margin: -1rem;
  }
}

.about-us-gradient-left {
  background-size: 900px 1600px;
  background-image: url("./assets/background/key-features-gradient.png");
  background-repeat: no-repeat;
  background-position: left;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 0 4rem;
  /* 2rem top and bottom, 4rem left and right */
}

@media only screen and (max-width: 481px) {
  .about-us-gradient-left {
    padding: 0 1rem;
    margin: -1rem;
  }
}

.breakthrough-gradient {
  background-size: 900px 800px;

  background-image: url("./assets/background/breakthrough-gradient.png");
  background-repeat: no-repeat;
  background-position: top right;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 0 4rem;
  /* 2rem top and bottom, 4rem left and right */
}

@media only screen and (max-width: 481px) {
  .breakthrough-gradient {
    padding: 0 1rem;
    margin: -1rem;
  }
}

.about-us-gradient-right {
  background-size: 900px 1000px;

  background-image: url("./assets/background/breakthrough-gradient.png");
  background-repeat: no-repeat;
  background-position: top right;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 0 4rem;
  /* 2rem top and bottom, 4rem left and right */
}

@media only screen and (max-width: 481px) {
  .about-us-gradient-right {
    padding: 0 1rem;
    margin: -1rem;
  }
}

.hide-scrollbar {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
