@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.slick-dots li {
  background: white;
  border-radius: 50%;
  width: 12px !important;
  height: 12px !important;
}

.slick-dots li.slick-active {
  background: #ab85fc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selected-dot {
  background-color: purple;
}

.gradient-border {
  border-width: 4px;
  border-radius: 2rem;
  border-image-slice: 1;
  border-style: solid;
  border-image-source: linear-gradient(to right, #ac86fc, #1997da);
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #1c1c1c; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #555; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color of the scrollbar thumb when hovered */
}

::-webkit-scrollbar-thumb:active {
  background: #aaa; /* Color of the scrollbar thumb when active */
}
